import axios from 'axios';
import { authenticator } from 'otplib';

import { CREATE_INVOICES_URL, NEW_SESSION_URL } from './constants';
import { onlyNumbers } from './utils';

interface ICreateInvoicesParams {
  vendor_cnpj: string;
  quantity: string;
  status: string;
  destroy_all: boolean;
  funder_cnpj: string;
}

export async function authLogin() {
  const response = await axios.post(NEW_SESSION_URL, {
    user: {
      cpf: '00000000000',
      pin: '1234',
      otp: authenticator.generate(process.env.REACT_APP_OTP_SECRET || ''),
    },
  });

  const { data } = response;

  return data?.jwt;
}

export async function adminLogin(authToken: string) {
  const response = await axios.get(NEW_SESSION_URL, {
    params: { service: 'admin' },
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  const { data } = response;

  return data?.jwt;
}

export async function createInvoices(
  adminToken: string,
  params: ICreateInvoicesParams
) {
  const { vendor_cnpj, quantity, status, destroy_all, funder_cnpj } = params;

  const response = await axios.post(
    CREATE_INVOICES_URL,
    {
      vendor_cnpj: onlyNumbers(vendor_cnpj),
      quantity: parseInt(quantity),
      status,
      destroy_all,
      funder_cnpj: onlyNumbers(funder_cnpj),
    },
    {
      timeout: 600000,
      headers: {
        Authorization: `Bearer ${adminToken}`,
        'Access-Control-Allow-Origin': '*',
      },
    }
  );

  const { data } = response;

  console.log(data);
}

import Lottie from 'react-lottie';
import animationData from '../../assets/animations/documents.json';
import { Container } from './styles';

interface ILoadingState {
  label: string;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function LoadingState(props: ILoadingState) {
  const { label } = props;

  return (
    <Container>
      <Lottie options={defaultOptions} width={320} height={320} speed={1.4} />
      <h4>{label}</h4>
    </Container>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const Input = styled.input.attrs(() => ({
  type: 'text',
}))`
  font-size: 16px;
  padding: 8px;
`;

import { Container, Label, Select } from './styles';

interface IDropdownList {
  label: string;
  selected: string;
  values: string[];
  onChange: Function;
}

function DropdownList(props: IDropdownList) {
  const { label, selected, values, onChange } = props;

  const handleChange = event => {
    onChange(event.target.value);
  };

  return (
    <Container>
      <Label>{label}</Label>
      <Select value={selected} onChange={handleChange}>
        {values.map(value => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </Select>
    </Container>
  );
}

export default DropdownList;

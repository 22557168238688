export const NEW_SESSION_URL = `${process.env.REACT_APP_AUTH_DOMAIN}/api/session`;

export const CREATE_INVOICES_URL = `${process.env.REACT_APP_MONOLITH_DOMAIN}/api/v1/invoices/create_invoices`;

export const INVOICE_STATUSES = [
  'inserido',
  'verificado',
  'venda_agendada',
  'venda_aberta',
  'venda_aguardando_aceite',
  'venda_finalizada',
];

export const INVOICE_QUANTITIES = ['1', '5', '10', '50', '100'];

export const BRADESCO_CNPJ = '60746948000112';
export const BTG_CNPJ = '30306294000145';

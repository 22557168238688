import { useEffect, useState, useMemo } from 'react';

import { adminLogin, authLogin, createInvoices } from './api';
import {
  BRADESCO_CNPJ,
  BTG_CNPJ,
  INVOICE_QUANTITIES,
  INVOICE_STATUSES,
} from './constants';
import TextInput from './components/TextInput';
import DropdownList from './components/DropdownList';
import {
  GlobalStyle,
  ScreenContainer,
  Title,
  Button,
  Row,
} from './global.styles';
import LoadingState from './components/LoadingState';

function App() {
  const [authToken, setAuthToken] = useState<string>('');
  const [adminToken, setAdminToken] = useState<string>('');
  const [hasRequestedOnce, setHasRequestedOnce] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [vendor_cnpj, setVendor_cnpj] = useState<string>('');
  const [quantity, setQuantity] = useState<string>('1');
  const [status, setStatus] = useState<string>('verificado');
  const [funder_cnpj, setFunder_cnpj] = useState<string>('');

  const [destroy_all] = useState<boolean>(false); // TODO: allow this choice?

  useEffect(() => {
    authLogin().then(setAuthToken);
  }, []);

  useEffect(() => {
    if (authToken) {
      adminLogin(authToken).then(setAdminToken);
    }
  }, [authToken]);

  useEffect(() => {}, [authToken]);

  const currentState = useMemo<string>(() => {
    if (!hasRequestedOnce)
      return 'Selecione as opções para criar as duplicatas :)';

    if (loading) {
      return 'Criando duplicatas...';
    } else if (error) {
      return 'Um erro ocorreu na criação das duplicatas! =/';
    } else {
      return 'Duplicatas criadas com sucesso!';
    }
  }, [hasRequestedOnce, loading, error]);

  const fillFunderCnpj = (cnpj: string) => () => {
    setFunder_cnpj(cnpj);
  };

  const requestCreateInvoices = () => {
    if (!hasRequestedOnce) setHasRequestedOnce(true);
    setLoading(true);
    setError(false);

    createInvoices(adminToken, {
      vendor_cnpj,
      quantity,
      status,
      destroy_all,
      funder_cnpj,
    })
      .then(() => setLoading(false))
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <ScreenContainer>
      <GlobalStyle />
      {loading ? (
        <LoadingState label="Criando duplicatas..." />
      ) : (
        <>
          <Title>Invoice Creator</Title>
          <TextInput
            label="CNPJ do Fornecedor"
            value={vendor_cnpj}
            onChange={setVendor_cnpj}
          />
          <TextInput
            label="CNPJ do Investidor"
            value={funder_cnpj}
            onChange={setFunder_cnpj}
          />
          <Row>
            <Button onClick={fillFunderCnpj(BTG_CNPJ)}>BTG</Button>
            <Button onClick={fillFunderCnpj(BRADESCO_CNPJ)}>Bradesco</Button>
          </Row>
          <DropdownList
            label="Quantidade"
            selected={quantity}
            values={INVOICE_QUANTITIES}
            onChange={setQuantity}
          />
          <DropdownList
            selected={status}
            label="Status"
            values={INVOICE_STATUSES}
            onChange={setStatus}
          />

          <h4>{currentState}</h4>
          <Button
            disabled={!adminToken || loading}
            onClick={requestCreateInvoices}
          >
            Criar duplicatas
          </Button>
        </>
      )}
    </ScreenContainer>
  );
}

export default App;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;

  div {
    margin-left: -8px !important;
  }
`;

export const Label = styled.div``;

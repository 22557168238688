import { Container, Input, Label } from './styles';

interface ITextInput {
  label: string;
  value: string;
  onChange: Function;
}

function TextInput(props: ITextInput) {
  const { label, value, onChange } = props;

  const handleChange = event => {
    onChange(event.target.value);
  };

  return (
    <Container>
      <Label>{label}</Label>
      <Input value={value} onChange={handleChange} />
    </Container>
  );
}

export default TextInput;

import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
  }
  body {
    color: #212121;
    margin: 0;
  }
  * {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    color: #212121;
  }
  h4 {
    font-weight: medium;
    font-size: 16px;
    line-height: 24px;
    min-height: 48px;
  }
`;

export const ScreenContainer = styled.div`
  width: 320px;
  margin: 0 auto;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;

  button {
    margin-right: 8px;
  }
`;

export const Button = styled.button`
  height: 40px;
  background-color: rgba(0, 157, 255, 1);
  color: white;
  box-shadow: 0px 2px 6px #23272fa3;
  border: 0px;
  border-radius: 8px;
  cursor: pointer;
  padding: 0 13px;
  font-size: 16px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  position: relative;
  overflow: hidden;

  svg {
    fill: white;
    color: white;
    height: 16px;
    width: 16px;
  }

  &:focus {
    outline: 0;
  }
  &::-moz-focus-inner {
    border: 0;
  }

  &:disabled {
    opacity: 0.38;
    cursor: default;
    pointer-events: none;
  }

  &:active {
    background-color: rgba(0, 157, 255, 0.67);
    box-shadow: 0px 0px 0px;
  }
`;
